<template>
  <v-card class="docs request-docs">
    <v-card-title>
      <div class="text-h5" style="word-break: break-word">{{ indicatorConfig.name }}</div>
    </v-card-title>
    <table>
      <thead>
      <tr>
        <th>Параметр</th>
        <th>Данные</th>
        <th class="org-comment__flag">&nbsp;</th>
        <th class="org-comment__control">Контроль</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="group in indicatorSequentialIndex(indicatorConfig)">
        <tr :key="indicatorConfig.id + '.' + group.id">
          <td colspan="5" style="word-break: break-word"><b>{{ group.name }}</b></td>
        </tr>

        <template v-for="zone in group.questions.map(q => q.zone).filter((v, i, a) => v && a.indexOf(v) === i)">
          <tr v-if="zone" :key="zone">
            <u>{{ zone }}</u>
          </tr>

          <tr v-for="question in group.questions.filter(q => q.zone === zone)"
              :key="group.id + '.' + question.id">
            <td>
              {{ question._index + 1 }}.
              {{ question.name }}
            </td>
            <td class="org-comment__params">
              <div class="standard-docs-params">
                <v-text-field
                    v-if="question.type==='integer'"
                    type="number"
                    :disabled="!checkRoles(editRoles)"
                    placeholder="Числовое значение"
                    :value="getLocalValue(question.id)?.comment"
                    @change="setCommentValue($event, question.id)"
                ></v-text-field>
                <v-select
                    v-else-if="question.type==='boolean'"
                    :disabled="!checkRoles(editRoles)"
                    placeholder="Да / Нет"
                    :items="yesNoItems"
                    :value="+getLocalValue(question.id)?.comment"
                    @change="setCommentValue($event, question.id)"
                ></v-select>
                <v-select
                    v-else-if="question.type==='DICT_SECTORS'"
                    :disabled="!checkRoles(editRoles)"
                    placeholder="Да / Нет"
                    :items="DICT_SECTORS"
                    :value="+getLocalValue(question.id)?.comment"
                    @change="setCommentValue($event, question.id)"
                ></v-select>
              </div>
            </td>
            <td class="org-comment__flag">
              <div class="flag-choose">
                <div class="flag-choose__colors">
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="green">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="yellow">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="red">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                </div>
                <v-radio-group
                    :disabled="
                    !checkRoles(controllerRoles) ||
                    getControlLevelNumber(getMaxControlInfo(question.id)?.controlLevel)>getControlLevelNumber(getUserControlLevel()) ||
                    ($user.role==='ROLE_CZN_MANAGER' && regionAndDepartmentInfo.department.type==='manager_czn')"
                    :value="getMaxControlInfo(question.id)?.flag"
                >
                  <v-radio :value="1" style="width: 0; height: 20px" @click="setControlFlag(1, question.id)"/>
                  <v-radio :value="2" style="width: 0; height: 20px" @click="setControlFlag(2, question.id)"/>
                  <v-radio :value="3" style="width: 0; height: 20px" @click="setControlFlag(3, question.id)"/>
                </v-radio-group>
              </div>
            </td>
            <td>
              <v-textarea
                  placeholder="Комментарий"
                  :disabled="
                    !getMaxControlInfo(question.id)?.flag ||
                    !checkRoles(controllerRoles) ||
                    getControlLevelNumber(getMaxControlInfo(question.id)?.controlLevel)>getControlLevelNumber(getUserControlLevel()) ||
                    ($user.role==='ROLE_CZN_MANAGER' && regionAndDepartmentInfo.department.type==='manager_czn')"
                  :value="getMaxControlInfo(question.id)?.comment"
                  @change="setControlComment($event, question.id)"
              ></v-textarea>
              <i v-if="getMaxControlInfo(question.id) && !isAutoControlInfo(getMaxControlInfo(question.id))">
                Контроль выполнен сотрудником
                {{(t = getMaxControlInfo(question.id)?.controlLevel)==='ROIV' ? 'РОИВ' : t==='VNII' ? 'ФЦК' : t==='MINTRUD' ? 'Минтруда России' : t==='CZN' ? 'управляющего ЦЗН' : 'Неизвестно'}}</i>
              <template v-if="getLocalValue(question.id)?.controlInfo != null && !isAutoControlInfo(getMaxControlInfo(question.id))">
                <div v-for="(ci, ciIndex) in getLocalValue(question.id).controlInfo"
                     :key="ciIndex+'_control'"
                     style="font-size: 75%">
                  <template v-if="ci.id !== getMaxControlInfo(question.id)?.id && !isAutoControlInfo(ci)">
                    Комментарий сотрудника {{(t = ci.controlLevel)==='ROIV' ? 'РОИВ' : t==='VNII' ? 'ФЦК' : t==='MINTRUD' ? 'Минтруда России' : t==='CZN' ? 'управляющего ЦЗН' : 'Неизвестно'}}:
                    {{ci.comment || '-'}}
                  </template>
                </div>
              </template>
            </td>
          </tr>
        </template>

        <tr v-for="question in group.questions.filter(q => !q.zone)"
            :key="group.id + '.' + question.id">
          <td>
            {{ question._index + 1 }}.
            {{ question.name }}
          </td>
          <td class="org-comment__params">
            <div class="standard-docs-params">
              <v-text-field
                  v-if="question.type==='integer'"
                  type="number"
                  :disabled="!checkRoles(editRoles)"
                  placeholder="Числовое значение"
                  :value="getLocalValue(question.id)?.comment"
                  @change="setCommentValue($event, question.id)"
              ></v-text-field>
              <v-select
                  v-else-if="question.type==='boolean'"
                  :disabled="!checkRoles(editRoles)"
                  placeholder="Да / Нет"
                  :items="yesNoItems"
                  :value="+getLocalValue(question.id)?.comment"
                  @change="setCommentValue($event, question.id)"
              ></v-select>
              <v-select
                  v-else-if="question.type==='DICT_SECTORS'"
                  :disabled="!checkRoles(editRoles)"
                  placeholder="Да / Нет"
                  :items="DICT_SECTORS"
                  :value="+getLocalValue(question.id)?.comment"
                  @change="setCommentValue($event, question.id)"
              ></v-select>
            </div>
          </td>
          <td class="org-comment__flag">
            <div class="flag-choose">
              <div class="flag-choose__colors">
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     fill="green">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     fill="yellow">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
                <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                     fill="red">
                  <circle cx="7" cy="7" r="7"/>
                </svg>
              </div>
              <v-radio-group
                  :disabled="
                    !checkRoles(controllerRoles) ||
                    getControlLevelNumber(getMaxControlInfo(question.id)?.controlLevel)>getControlLevelNumber(getUserControlLevel()) ||
                    ($user.role==='ROLE_CZN_MANAGER' && regionAndDepartmentInfo.department.type==='manager_czn')"
                  :value="getMaxControlInfo(question.id)?.flag"
              >
                <v-radio :value="1" style="width: 0; height: 20px" @click="setControlFlag(1, question.id)"/>
                <v-radio :value="2" style="width: 0; height: 20px" @click="setControlFlag(2, question.id)"/>
                <v-radio :value="3" style="width: 0; height: 20px" @click="setControlFlag(3, question.id)"/>
              </v-radio-group>
            </div>
          </td>
          <td>
            <v-textarea
                placeholder="Комментарий"
                :disabled="
                    !getMaxControlInfo(question.id)?.flag ||
                    !checkRoles(controllerRoles) ||
                    getControlLevelNumber(getMaxControlInfo(question.id)?.controlLevel)>getControlLevelNumber(getUserControlLevel()) ||
                    ($user.role==='ROLE_CZN_MANAGER' && regionAndDepartmentInfo.department.type==='manager_czn')"
                :value="getMaxControlInfo(question.id)?.comment"
                @change="setControlComment($event, question.id)"
            ></v-textarea>
            <i v-if="getMaxControlInfo(question.id) && !isAutoControlInfo(getMaxControlInfo(question.id))">
              Контроль выполнен сотрудником
              {{(t = getMaxControlInfo(question.id)?.controlLevel)==='ROIV' ? 'РОИВ' : t==='VNII' ? 'ФЦК' : t==='MINTRUD' ? 'Минтруда России' : t==='CZN' ? 'управляющего ЦЗН' : 'Неизвестно'}}
            </i>
            <template v-if="getLocalValue(question.id)?.controlInfo != null && !isAutoControlInfo(getMaxControlInfo(question.id))">
              <div v-for="(ci, ciIndex) in getLocalValue(question.id).controlInfo"
                   :key="ciIndex+'_control'"
                   style="font-size: 75%">
                <template v-if="ci.id !== getMaxControlInfo(question.id)?.id && !isAutoControlInfo(ci)">
                  Комментарий сотрудника {{(t = ci.controlLevel)==='ROIV' ? 'РОИВ' : t==='VNII' ? 'ФЦК' : t==='MINTRUD' ? 'Минтруда России' : t==='CZN' ? 'управляющего ЦЗН' : 'Неизвестно'}}:
                  {{ci.comment || '-'}}
                </template>
              </div>
            </template>
          </td>
        </tr>
      </template>
      </tbody>
    </table>

    <v-card-title>
      <div class="text-h6">Документы</div>
    </v-card-title>
    <table>
      <tbody>
      <tr v-for="(source, sourceIndex) in sources" :key="sourceIndex+'_source'">
        <td>
          {{ source }}
        </td>
        <td>
          <div v-if="showAttachmentBlock && localAttachments.filter(e => e.source === source && !e.deleted).length" style="color: red">
            Недопустимо прикрепление документов при отсутствии положительных ответов
          </div>
          <template v-for="attachment in localAttachments.filter(e => e.source === source && !e.deleted)">
            <div :key="attachment.file.id + 'fileIndex'" class="d-flex align-center">
              <svg width="10" height="20" viewBox="0 0 10 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg"
                   class="mr-2">
                <path
                    d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                    fill="#777779"/>
              </svg>
              <a :href="$backendUrl + '/uuidfs/' + attachment.file.id"
                 @click.prevent="downloadFile(attachment.file.id, attachment.file.name)">
                {{ truncateStr(attachment.file.name, 30) }}
              </a>
              <a @click="deleteFile(attachment)"
                 v-if="checkRoles(editRoles)">
                <v-icon class="icon-item icon-window-close"></v-icon>
              </a>
            </div>
          </template>
          <div v-if="localAttachments.filter(e => e.source === source && !e.deleted)?.length < 1 && checkRoles(editRoles)">
            <input type="file" :ref="'fileupload_' + source"
                   style="display:none"
                   @change="addFiles($event.target.files, source)">
            <div v-if="showAttachmentRequiredError && source !== 'Иные'" style="color: red">
              Прикрепите документ
            </div>
            <div>
              <a
                  @click="$refs['fileupload_'+source][0].click()"
                  class="upload-link"><i>Добавить файл</i></a>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div style="color: red; margin-left: 300px">
      {{errorText}}
    </div>

    <v-card-actions>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Отмена
      </v-btn>
      <v-btn
          class="btn btn-primary"
          @click="submit()"
          :disabled="(loading || checkRoles(editRoles)) && $user.role !== 'ROLE_ADMIN'"
      >
        Сохранить изменения
      </v-btn>
    </v-card-actions>
    <loader-dialog :show="loading" msg="Сохранение"></loader-dialog>
  </v-card>
</template>

<script>

import api from "@/modules/api";
import {getStage, saveAttachments, saveValues} from "@/modules/OrgMonitor";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'OrgMonitorControlDialog',
  components: {LoaderDialog},
  props: ['regionAndDepartmentInfo', 'indicatorConfig', 'values', 'attachments', 'sectionId', 'indicatorId', 'period'],

  data: () => ({
    stage: null,
    sources: [],
    showAttachmentRequiredError: false,
    showAttachmentBlock: false,
    flags: ['#89FF8E', '#F8FC56', '#FF7272'],
    answerStatuses: ['WELL', 'SATISFACTORILY', 'UNSATISFACTORY'],
    controllerRoles: ['ROLE_VNII', 'ROLE_REGIONAL_AGENCY', 'ROLE_MINTRUD', 'ROLE_CZN_MANAGER'],
    editRoles: ['ROLE_CZN_MANAGER', 'ROLE_CZN_TERRITORIAL', 'ROLE_ADMIN'],
    localValues: [],
    localAttachments: [],
    errorText: null,
    yesNoItems: [
      {
        value: 0,
        text: 'Нет'
      },
      {
        value: 1,
        text: 'Да'
      }
    ],
    DICT_SECTORS: [
      {
        value: 0,
        text: 'Нет'
      },
      {
        value: 1,
        text: 'Да'
      },
      {
        value: 2,
        text: 'Совмещено'
      }
    ],
    loading: false
  }),
  methods: {
    //Для сквозной нумерации вопросов без учета зон
    indicatorSequentialIndex(indicator) {
      return indicator.groups.map(group => {
        let index = 0
        group.questions.forEach(question => {
          question._index = index++
        })
        return group
      })
    },
    //Клонирует values в localValues
    setLocalValues() {
      this.localValues = JSON.parse(JSON.stringify(this.values.filter(e => e.sectionId === this.sectionId && e.indicatorId === this.indicatorId && this.period === this.period)))
      this.localAttachments = JSON.parse(JSON.stringify(this.attachments.filter(e => e.sectionId === this.sectionId && e.indicatorId === this.indicatorId && this.period === this.period)))
    },

    getLocalValue(questionId) {
      return this.getLocalValueWithPeriod(questionId, this.period)
    },

    getLocalValueWithPeriod(questionId, period) {
      return this.localValues.find(
          e => e.period === period && e.sectionId === this.sectionId && e.indicatorId === this.indicatorId && e.questionId === questionId
      )
    },

    async deleteFile(attachment) {
      attachment.deleted = true
      this.$forceUpdate()
    },

    createEmptyParameterValue(questionId) {
      return {
        id: null,
        department: this.regionAndDepartmentInfo.department,
        sectionId: this.sectionId,
        indicatorId: this.indicatorId,
        questionId: questionId,
        period: this.period,
        comment: null,
      }
    },

    async addFiles(files, source) {
      this.loading = true
      for (let file of files) {
        let uploadRes = await this.uploadFile(file, null)
        if (uploadRes) {
          this.localAttachments.push({
            configVersion: "1.0",
            department: this.regionAndDepartmentInfo.department,
            sectionId: this.sectionId,
            indicatorId: this.indicatorId,
            source: source,
            period: this.period,
            file: uploadRes,
            deleted: false
          })
        }
      }
      this.loading = false
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },

    async uploadFile(file, meta) {
      if (file.size > 52428800) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        return null
      }
      let formData = new FormData();
      formData.append('file', file);
      if (meta) {
        formData.append('meta', meta)
      }
      let req = await api.postFile('/uuidfs/', {}, formData, 'POST');
      if (req.ok) {
        return req.payload
      } else {
        alert('Ошибка загрузки файла: ' + req.error)
        console.log('upload error', req.error)
      }
    },

    getQuestionValueOrCreateNew(questionId) {
      let questionValue = this.getLocalValue(questionId)
      if (!questionValue) {
        questionValue = this.createEmptyParameterValue(questionId)
        this.localValues.push(questionValue)
      }
      return questionValue
    },

    getQuestionValueOrCreateNewWithControlInfo(questionId) {
      let questionValue = this.getQuestionValueOrCreateNew(questionId)
      if (!questionValue.controlInfo) {
        questionValue.controlInfo = []
      }
      return questionValue
    },

    setCommentValue(value, questionId) {
      this.getQuestionValueOrCreateNew(questionId).comment = value
    },

    getUserControlLevel(){
      switch (this.$user.role){
        case 'ROLE_VNII': case 'ROLE_ADMIN': return 'VNII'
        case 'ROLE_MINTRUD': return 'MINTRUD'
        case 'ROLE_REGIONAL_AGENCY': return 'ROIV'
        case 'ROLE_CZN_MANAGER': return 'CZN'
      }
    },

    getControlLevelNumber(controlLevel){
      switch (controlLevel){
        case 'CZN': return 0
        case 'ROIV': return 10
        case 'VNII': return 20
        case 'MINTRUD': return 30
      }
    },

    getMaxControlInfo(questionId){
      let questionValue = this.getLocalValue(questionId)
      if(!questionValue || !questionValue.controlInfo) return null
      return questionValue.controlInfo.find(e=>e.controlLevel==='MINTRUD') ||
          questionValue.controlInfo.find(e=>e.controlLevel==='VNII') ||
          questionValue.controlInfo.find(e=>e.controlLevel==='ROIV') ||
          questionValue.controlInfo.find(e => e.controlLevel === 'CZN')
    },

    setControlFlag(value, questionId) {
      let questionValue = this.getQuestionValueOrCreateNewWithControlInfo(questionId)
      let userControlLevel = this.getUserControlLevel()

      let controlInfo = questionValue.controlInfo.find(e => e.controlLevel === userControlLevel)
      if (!controlInfo) {
        controlInfo = {
          controlLevel: userControlLevel,
          flag: value
        }
        questionValue.controlInfo.push(controlInfo)
      } else {
        controlInfo.flag = value
      }

      this.$forceUpdate()
    },

    setControlComment(value, questionId) {
      let questionValue = this.getQuestionValueOrCreateNewWithControlInfo(questionId)
      let userControlLevel = this.getUserControlLevel()
      let maxControlInfo = this.getMaxControlInfo(questionId)

      let controlInfo = questionValue.controlInfo.find(e => e.controlLevel === userControlLevel)
      if (!controlInfo) {
        controlInfo = {
          controlLevel: userControlLevel,
          comment: value
        }
        questionValue.controlInfo.push(controlInfo)
      } else {
        controlInfo.comment = value
      }

      // Переносим значение флага с наивысшего уровня контроля, если оно существует
      if (maxControlInfo && maxControlInfo.controlLevel !== userControlLevel && maxControlInfo.flag) {
        controlInfo.flag = maxControlInfo.flag
      }

      this.$forceUpdate()
    },

    truncateStr(str, n) {
      if (!str) return ''
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async submit() {
      this.loading = true
      if (!this.validateBeforeSubmit()) {
        this.loading = false
        return
      }

      await saveValues(this.localValues)
      await saveAttachments(this.localAttachments)
      this.dialog = false
      this.loading = false
      this.$emit('updated', this);
    },

    checkFormula: function(formula){
      let adaptedFormula = formula.replaceAll(/q(\d+)/g, (a, i) => '(+this.getLocalValue(' + i + ')?.comment || 0)')
      return eval(adaptedFormula)
    },

    validateDataTypes(){
      for (let group of this.indicatorConfig.groups) {
        for (let q of group.questions) {
          let value = this.getLocalValue(q.id)?.comment
          if (q.type === 'integer') {
            value = +value
            if (!Number.isInteger(value) || value < 0) {
              this.errorText = `Некорректное значение ${value}: допускаются только целые неотрицательные числа`
              return false
            }
          }
          if (q.type === 'boolean') {
            if (value == null) {
              this.errorText = `Необходимо заполнить все поля`
              return false
            }
          }
        }
      }

      return true
    },

    validateAttachments() {
      // В этих индикаторах особая валидация на файлы через flk
      if (this.indicatorConfig.id === 28 || this.indicatorConfig.id === 14 || this.indicatorConfig.id === 15 || this.indicatorConfig.id === 16) return true

      this.showAttachmentRequiredError = false
      this.showAttachmentBlock = false

      //Выбран хотя бы один вариант ответа "да/совмещено"
      const hasYesAnswers = this.localValues.filter(value => value.comment && +value.comment > 0).length

      if (this.checkRoles(this.editRoles)) {
        if (hasYesAnswers) {
          for (let source of this.sources) {
            if (source === 'Иные') continue
            const attachment = this.localAttachments.find(e => e.source === source && !e.deleted)

            if (!attachment) {
              this.showAttachmentRequiredError = true
              return false
            }
          }
        } else {
          for (let source of this.sources) {
            const attachment = this.localAttachments.find(e => e.source === source && !e.deleted)

            if (attachment) {
              this.showAttachmentBlock = true
              return false
            }
          }
        }
      }

      return true
    },

    validateBeforeSubmit() {
      let flkError = false
      let controlError = false
      this.errorText = null

      if (!this.validateDataTypes()) {
        return false
      }

      if (!this.validateAttachments()) {
        return false
      }

      this.localValues.forEach(value => {
        if (value.controlInfo) {
          value.controlInfo.forEach(control => {
            if (control.controlLevel === this.getUserControlLevel()) {
              if (control.flag > 1 && (!control.comment || control.comment.length === 0)) {
                this.errorText = "Необходимо указать комментарий для красного и/или жёлтого индикатора контроля"
                controlError = true
              }
            }
          })
        }
      })

      //Проверки из файла конфигурации
      if (this.indicatorConfig.flk) {
        flkError = this.checkFormula(this.indicatorConfig.flk)
        if (flkError) {
          this.errorText = flkError
        }
      }

      return !flkError && !controlError;
    },

    isAutoControlInfo(controlInfo) {
      return controlInfo?.comment ===
          'Красный индикатор проставлен автоматически в связи с отсутствием контроля РОИВ по состоянию на 15.07.2024'
    },

    async downloadFile(fileId, fileName) {
      this.loading = true
      let req = await api.download("/uuidfs/" + fileId, {});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
      this.loading = false
    },
  },
  async beforeMount() {
    this.sources = this.indicatorConfig.sources?.split('\n') || []
    this.sources.push('Иные')
    this.setLocalValues()
    this.stage = await getStage()
  }
}
</script>

<style scoped lang="scss">
.standard-docs-params {
  gap: 10px;
}

.standard-docs-file {
  padding-left: 25px;
  background: url(~@/assets/attach.svg) no-repeat left center;
}

.standard-docs-comment {
  background: url(~@/assets/chat.svg) no-repeat left center;
  padding-left: 25px;
}

.org-comment__control {
  min-width: 200px;
}

.org-comment__flag {
  & > div {
    position: relative;
    min-width: 40px;

    input[type="radio"] {
      display: none;
    }

    label {
      display: block;
      height: 16px;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        border-radius: 10px;
        background: #fff;
        border: 1px solid $cblue;
      }
    }

    input:checked + label:after {
      content: '';
      position: absolute;
      right: 4px;
      top: calc(50%);
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transition: all 0.2s;
      background: $cblue;
    }

  }

  & > div + div {
    margin-top: 5px;
  }
}
</style>


