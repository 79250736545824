<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <p>Наименование отчитывающейся организации: {{ getCznInfo()?.name }}</p>
          <p>Адрес: {{ getCznInfo()?.address }}</p>
          <p>Уровень: Региональный орган исполнительной власти
          </p>
          <div class="list passport" v-if="config.sections.length !== 0">
            <div v-for="(section, sectionIndex) in config.sections"
                 :key="'section_' + section.id"
                 class="list-item-wrap">
              <v-expansion-panels>
                <v-expansion-panel :key="'vep' + section.id">
                  <v-expansion-panel-header>
                    <div class="list-item">
                      <a class="list-item-name">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                               width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                               width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{ sectionIndex + 1 }}. {{ section.name }}</span>
                      </a>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-expansion-panels v-if="section.indicators.length !== 0">
                      <v-expansion-panel v-for="(indicator, indicatorIndex) in section.indicators"
                                         :key="'indicator_' + section.id + '.' + indicator.id"
                                         class="level2">
                        <div class="list-item-wrap">
                          <v-expansion-panel-header @keyup.space.prevent>
                            <div class="list-item">
                              <a class="list-item-name">
                                <button class="toggleSwitch whiteBg">
                                  <svg class="closed" fill="none" height="24"
                                       viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z"
                                          fill="#1551D0"/>
                                  </svg>
                                  <svg class="opened" fill="none" height="24"
                                       viewBox="0 0 24 24" width="24"
                                       xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z"
                                          fill="#1551D0"/>
                                  </svg>
                                </button>
                                <span>{{ sectionIndex + 1 }}.{{ indicatorIndex + 1 }}. {{ indicator.name }}</span>
                              </a>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <table>
                              <thead>
                              <tr>
                                <th>Базовый показатель</th>
                                <th>
                                  <template v-if="indicator.baseIndicatorType === 'INTEGER'">
                                    Количество
                                  </template>
                                  <template v-else>
                                    Код ответа<br>(1 - да; 0 - нет)
                                  </template>
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-if="indicator.level === 'ТЦЗН' || indicator.level === 'Рабочее_место'">
                                <td>
                                  Общее количество территориальных центров занятости населения, шт.
                                </td>
                                <td>
                                  {{ getCznsInRegionCount() }}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{ indicator.baseIndicatorName }}
                                </td>
                                <td>
                                  {{ getBaseIndicatorValue(indicator.id) }}
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </v-expansion-panel-content>
                        </div>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<script>

import api from "@/modules/api";
import {getCznsInRegionCount, loadConfig, parseValuesToFloat} from "@/modules/OrgMonitor";
import {getRegionIndicatorsByPeriod} from "@/modules/api.indicators";

export default {
  name: "OrgMonitorCheckListBase",
  components: {
  },
  props: ['regionAndDepartmentInfo', 'period'],
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      },
      deep: true
    },
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.initialize();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },

  data: () => ({
    id: null,
    domain: 'org_monitor',
    values: [],
    attachments: [],
    config: [],
    apiLoaded: false,
    errorText: null,
    dialog: false
  }),

  methods: {
    getCznInfo() {
      return this.regionAndDepartmentInfo.region.departments.find(e => e.type === 'oiv')
    },

    async initialize() {
      this.config = await loadConfig()
      const regionCode = this.regionAndDepartmentInfo.region.code
      this.values = parseValuesToFloat((await getRegionIndicatorsByPeriod(this.domain, regionCode, this.period)).payload)
    },

    getBaseIndicatorValue(indicatorId) {
      let result = this.values.find(e => e.indicatorId === indicatorId)?.value
      if (result != null && !isNaN(result)) {
        return result
      }
      return 0
    },

    getCznsInRegionCount() {
      return getCznsInRegionCount(this.regionAndDepartmentInfo.region.code)
    },

    truncateStr(str, n = 30) {
      if (!str) return str
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str;
    },

    async downloadFile(fileId, fileName) {
      let req = await api.download("/uuidfs/" + fileId, {});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    }
  },

  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },

  async beforeMount() {
    await this.initialize()
    this.apiLoaded = true;
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/main";

h1 {
  display: flex;
  align-items: center;

  .badge {
    width: 152px;
    height: 60px;
    background: #E8E8E9;
    border: 1px solid #E8E8E9;
    border-radius: 4px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 70px;
    color: $cblack;
  }

  .v-progress-circular {
    margin-left: 20px;
  }

  .v-btn {
    margin-left: auto;
  }
}

.manual-link {
  margin: 20px 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin-bottom: 20px;

    .v-icon {
      color: #0033a0;
      margin-right: 10px;
    }
  }
}

.list {
  .list-item-wrap {
    padding: 5px 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.clientindex {
  .v-expansion-panel.level2 {
    padding-left: 30px;
    padding-top: 0px;

    .list-item-wrap {
      border-bottom: 0;
    }

    & + .v-expansion-panel.level2 {
      .list-item-wrap {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.push-right {
  float: right;
  margin-top: 15px;
}

.v-progress-circular__info {
  font-size: 13px !important;
  text-align: center;
  width: 100%;
}
</style>
